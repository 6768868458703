import React from 'react'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


const HandleRouting = () => {

    const navigate = useNavigate();

    const shouldRedirect = true;

    useEffect(() => {

        let targetRoute = '';

        const currentUrl = window.location.href;

        if (currentUrl.includes('/seed-dressing')) {
            targetRoute = '/services/seed-dressing/1'
        }

        if (currentUrl.includes('/seed-grading')) {
            targetRoute = '/services/seed-grading/2'
        }

        if (currentUrl.includes('/seed-cleaning')) {
            targetRoute = '/services/seed-cleaning/3'
        }

        if (currentUrl.includes('/3-reasons-why-quality-seeds-count')) {
            targetRoute = '/blog/3-reasons-why-quality-seeds-count/34'
        }

        if (currentUrl.includes('/understanding-the-importance-of-seed-vigour')) {
            targetRoute = '/blog/understanding-the-importance-of-seed-vigour/33'
        }

        if (currentUrl.includes('/walco-seed-cleaning-does-it-again')) {
            targetRoute = '/blog/walco-seed-cleaning-does-it-again/32'
        }

        if (currentUrl.includes('/a-brief-note-on-seed-treatments-for-sustainable-agriculture')) {
            targetRoute = '/blog/a-brief-note-on-seed-treatments-for-sustainable-agriculture/31'
        }

        if (currentUrl.includes('/why-should-organic-farmers-consider-seed-cleaning-for-crop-selection')) {
            targetRoute = '/blog/why-should-organic-farmers-consider-seed-cleaning-for-crop-selection/30'
        }

        if (currentUrl.includes('/major-causes-of-crop-diseases-and-their-preventive-measures')) {
            targetRoute = '/blog/major-causes-of-crop-diseases-and-their-preventive-measures/29'
        }

        if (currentUrl.includes('/how-beneficial-is-nutritional-seed-dressing-to-the-crops')) {
            targetRoute = '/blog/how-beneficial-is-nutritional-seed-dressing-to-the-crops/28'
        }

        if (currentUrl.includes('/5-significant-steps-to-seed-cleaning')) {
            targetRoute = '/blog/5-significant-steps-to-seed-cleaning/27'
        }

        if (currentUrl.includes('/how-nutritional-seed-dressing-can-help-seeds-cope-with-stressors')) {
            targetRoute = '/blog/how-nutritional-seed-dressing-can-help-seeds-cope-with-stressors/26'
        }

        if (currentUrl.includes('/why-should-you-invest-in-seed-cleaning')) {
            targetRoute = '/blog/why-should-you-invest-in-seed-cleaning/25'
        }

        navigate(targetRoute);

    }, [navigate, shouldRedirect]);

    return (
        <div>HandleRouting</div>
    )
}

export default HandleRouting