import '../../App.css';


import React, { Suspense } from 'react'
import Header from './header/Header';
import Footer from './footer/Footer';
import { Outlet } from 'react-router-dom';

const frontend = () => {
  return (
    <>
      <Suspense fallback="loading..">
        <Header />
        <Outlet />
        <Footer />
      </Suspense>
    </>

  )
}

export default frontend
