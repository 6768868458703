import React, { useState, useEffect, useRef, memo } from 'react';

import Banner from '../assets/image/1.webp';

import { IoHomeOutline } from 'react-icons/io5';
import { HiOutlineMail } from 'react-icons/hi'
import { AiOutlinePhone } from 'react-icons/ai'
import { Link, NavLink } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { TfiAngleDoubleUp } from 'react-icons/tfi'
import axios from 'axios';
import dateFormat from 'dateformat';
import { FaPhoneAlt, FaFacebook, FaGoogle } from 'react-icons/fa'
import { MdEmail } from 'react-icons/md';


const Footer = () => {

	const api_url = process.env.REACT_APP_API_URL;


	const [data, setData] = useState([]);
	const [logo, setLogo] = useState('');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	const [banners, setBanners] = useState([]);
	const [address, setAddress] = useState([]);
	const [blogs, setBlogs] = useState([]);



	const getAllData = () => {



		axios.get(`${api_url}/getFooter`).then((response) => {
			// console.log(response.data);
			// setLogo(response?.data?.data[0].logo)
			setPhone(response?.data?.data[0].phone)
			setEmail(response?.data?.data[0].enq_email)
			setAddress(response?.data?.data[0].address)
			setData(response?.data?.data)
		}).catch((err) => {
			// console.log(err);
		});

		axios.get(`${api_url}/getcardBlog`).then((response) => {
			// console.log(response?.data?.data)
			setBlogs(response?.data?.data);
		}).catch((err) => {
			// console.log(err)
		});




	}

	useEffect(() => {
		getAllData();
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, []);

	const [showScrollBtn, setShowScrollBtn] = useState(false);

	const toggleVisible = () => {
		const scrolled = document.documentElement.scrollTop;
		if (scrolled > 300) {
			setShowScrollBtn(true)
		}
		else if (scrolled <= 300) {
			setShowScrollBtn(false)
		}
	};

	window.addEventListener('scroll', toggleVisible);

	return (
		<>
			<footer>
				<div data-elementor-type="wp-post" data-elementor-id="317" class="elementor elementor-317">
					<div class="elementor-inner">
						<div class="elementor-section-wrap">

							<section
								class="elementor-section elementor-top-section elementor-element elementor-element-b933d34 elementor-section-full_width elementor-section-stretched bg-dark text-light elementor-section-height-default elementor-section-height-default"
								data-id="b933d34" data-element_type="section" data-settings="">
								<div class="elementor-background-overlay" style={{ backgroundImage: `url(${Banner})` }}></div>
								<div class="elementor-container elementor-column-gap-no">
									<div class="elementor-row">
										<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-442865f"
											data-id="442865f" data-element_type="column" data-settings="">
											<div class="elementor-column-wrap elementor-element-populated">
												<div class="elementor-widget-wrap">

													<section
														class="elementor-section elementor-inner-section elementor-element elementor-element-56e1f30 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
														data-id="56e1f30" data-element_type="section" data-settings="">
														<div class="elementor-background-overlay"></div>
														<div class="elementor-container elementor-column-gap-no">
															<div class="elementor-row">
																<div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-1013c9c footer-item about"
																	data-id="1013c9c" data-element_type="column">
																	<div
																		class="elementor-column-wrap elementor-element-populated">
																		<div class="elementor-widget-wrap">
																			<div class="elementor-element elementor-element-7482b25 elementor-widget elementor-widget-image"
																				data-id="7482b25" data-element_type="widget"
																				data-widget_type="image.default">
																				<div class="elementor-widget-container">
																					{/* <div class="elementor-image">
																					<a href=""><img
																			src={`assets/logo/${logo}`} alt="walcoseed" /></a>
																					</div> */}
																				</div>
																			</div>
																			<div class="elementor-element elementor-element-555fad3 elementor-widget elementor-widget-text-editor"
																				data-id="555fad3" data-element_type="widget"
																				data-widget_type="text-editor.default">
																				<div class="elementor-widget-container">
																					<div
																						class="elementor-text-editor elementor-clearfix">
																						<p>Walco Seed Cleaning is a mobile on-farm operation giving farmers the best quality service based
																							in South Australia.In 2001, Kurt Walter started his seed grading career by learning from
																							the best in the business, Ian Sanders from Sanders Seed Cleaners When Ian retired, Kurt and his partner Merey Colquhoun
																							started up Walco Seed Cleaning in 2005 and created a diverse business in the seed
																							cleaning industry within South Australia.</p>
																					</div>
																				</div>
																			</div>

																		</div>
																	</div>
																</div>
																<div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-0031640 footer-item link"
																	data-id="0031640" data-element_type="column">
																	<div
																		class="elementor-column-wrap elementor-element-populated">
																		<div class="elementor-widget-wrap">
																			<div class="elementor-element elementor-element-9f62eaa elementor-widget elementor-widget-heading"
																				data-id="9f62eaa" data-element_type="widget"
																				data-widget_type="heading.default">
																				<div class="elementor-widget-container">
																					<h4
																						class="elementor-heading-title elementor-size-default">
																						Explore</h4>
																				</div>
																			</div>
																			<div class="elementor-element elementor-element-7e30246 elementor-widget elementor-widget-wp-widget-nav_menu"
																				data-id="7e30246" data-element_type="widget"
																				data-widget_type="wp-widget-nav_menu.default">
																				<div class="elementor-widget-container">
																					<div class="menu-explore-container">
																						<ul id="menu-explore" class="menu">
																							<li id="menu-item-1532"
																								class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1532">
																								<NavLink end
																									to="/about">About
																									Us</NavLink></li>
																							<li id="menu-item-1533"
																								class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1533">
																								<NavLink end
																									to="why-grade-seed">Why Grade Seed</NavLink></li>
																							<li id="menu-item-1534"
																								class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1534">
																								<NavLink end
																									to="/what-we-do">What We Do</NavLink>
																							</li>
																							<li id="menu-item-1533"
																								class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1533">
																								<NavLink end
																									to="the-grain-detective">The Grain Detective</NavLink></li>
																							<li id="menu-item-1537"
																								class="menu-item menu-item-type-custom menu-item-object-custom menu-item-1537">
																								<NavLink end
																									to="/treatments">Treatments</NavLink></li>
																							<li id="menu-item-1536"
																								class="menu-item menu-item-type-custom menu-item-object-custom menu-item-1536">
																								<NavLink end
																									to="/blog">Blog</NavLink>
																							</li>
																							<li id="menu-item-1535"
																								class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1535">
																								<NavLink end
																									to="/contact-us">Contact
																									Us</NavLink></li>
																							<li id="menu-item-1535"
																								class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1535">
																								<NavLink end
																									to="/guarantee">Guarantee</NavLink></li>
																							<li id="menu-item-1535"
																								class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1535">
																								<NavLink end
																									to="/faqs">FAQs
																								</NavLink></li>

																						</ul>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
																<div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-61b896d footer-item recent-post"
																	data-id="61b896d" data-element_type="column">
																	<div
																		class="elementor-column-wrap elementor-element-populated">
																		<div class="elementor-widget-wrap">
																			<div class="elementor-element elementor-element-15531dd elementor-widget elementor-widget-heading"
																				data-id="15531dd" data-element_type="widget"
																				data-widget_type="heading.default">
																				<div class="elementor-widget-container">
																					<h4
																						class="elementor-heading-title elementor-size-default">
																						Recent Posts</h4>
																				</div>
																			</div>
																			<div class="elementor-element elementor-element-f6ea3ad elementor-widget elementor-widget-wp-widget-agrul-recent-posts"
																				data-id="f6ea3ad" data-element_type="widget"
																				data-widget_type="wp-widget-agrul-recent-posts.default">
																				<div class="elementor-widget-container">
																					<ul>
																						{blogs?.map((data) => {
																							return (
																								<li>
																									<div class="thumb">
																										{/* <a href={`#/blog/${data.id}`}>

																											<img src={`assets/blog/${data.image}`}
																												alt="Walcoseeds" loading='lazy' width={100} height={100} />
																										</a> */}
																									</div>
																									<div class="info">
																										<div class="meta-title">
																											<span
																												class="post-date">{dateFormat(`${data.created_at}`, "fullDate")}</span>
																										</div>
																										<Link to={`/blog/${data.title.replace(/\s/g, '-').toLowerCase()}/${data.id}`}>{data.title}</Link>
																									</div>
																								</li>
																							)
																						})}
																					</ul>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
																<div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-ba2227e"
																	data-id="ba2227e" data-element_type="column"
																	data-settings="">
																	<div
																		class="elementor-column-wrap elementor-element-populated">
																		<div class="elementor-widget-wrap">
																			<div class="elementor-element elementor-element-8cd23b0 elementor-widget elementor-widget-text-editor"
																				data-id="8cd23b0" data-element_type="widget"
																				data-widget_type="text-editor.default">
																				<div class="elementor-widget-container">
																					<div
																						class="elementor-text-editor elementor-clearfix">
																						<div class="footer-item contact">
																							<h4 class="widget-title">Contact
																								Info</h4>
																							<ul>
																								<li>
																									<div class="icon">
																										<i
																											class="fas fa-home"><IoHomeOutline /></i>
																									</div>
																									<div class="content">
																										<strong>Address:</strong>
																										<a target='_blank' href="https://goo.gl/maps/fEEm6nhJ3P2zMgsb6">{address}</a>

																									</div>
																								</li>
																								<li>
																									<div class="icon">
																										<i
																											class="fas fa-envelope"><HiOutlineMail /></i>
																									</div>
																									<div class="content">
																										<strong>Email:</strong>
																										<a href={`mailto:${email}`}>{email}</a>
																									</div>
																								</li>
																								<li>
																									<div class="icon">
																										<i
																											class="fas fa-phone"><AiOutlinePhone /></i>
																									</div>
																									<div class="content">
																										<strong>Phone:</strong>
																										<a href={`tel:${phone}`}>{phone}</a>
																									</div>
																								</li>
																							</ul>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</section>

													<section
														class="elementor-section elementor-inner-section elementor-element elementor-element-f5c3c4c footer-bottom elementor-section-boxed elementor-section-height-default elementor-section-height-default"
														data-id="f5c3c4c" data-element_type="section" data-settings="">
														<div class="elementor-container elementor-column-gap-no">
															<div class="elementor-row">
																<div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-216befc"
																	data-id="216befc" data-element_type="column"
																	data-settings="">
																	<div
																		class="elementor-column-wrap elementor-element-populated">
																		<div class="elementor-widget-wrap">
																			<div class="elementor-element elementor-element-b14686c elementor-widget elementor-widget-text-editor"
																				data-id="b14686c" data-element_type="widget"
																				data-widget_type="text-editor.default">
																				<div class="elementor-widget-container">
																					<div
																						class="elementor-text-editor elementor-clearfix">
																						© 2023 Walco Seed Cleaning. All Rights Reserved.
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
																<div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-31e8334 text-end"
																	data-id="31e8334" data-element_type="column">
																	<div
																		class="elementor-column-wrap elementor-element-populated">
																		<div class="elementor-widget-wrap">
																			<div class="elementor-element elementor-element-c4f6928 elementor-widget elementor-widget-text-editor"
																				data-id="c4f6928" data-element_type="widget"
																				data-widget_type="text-editor.default">
																				<div class="elementor-widget-container">
																					<div
																						class="elementor-text-editor elementor-clearfix">
																						<ul class="footer-bottom-link">
																							<li><Link
																								to={`/privacy-statement`}>Privacy Statement</Link>
																							</li>
																							<li><Link
																								to={`/disclaimer`}>Disclaimer</Link>
																							</li>
																							<li><Link
																								to={`/guarantee`}>Guarantee</Link>
																							</li>
																						</ul>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</section>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
						</div>
					</div>
				</div>
			</footer>
			<a href={`mailto:${email}`}>
				<button style={{ borderRadius: '5.745744096650192vw', transition: 'auto' }}
					className='mail_btn' >
					<MdEmail />
					{/* <BiMessageDetail /> */}
				</button>
			</a>
			<a href={`tel:${phone}`}>
				<button style={{ borderRadius: '5.745744096650192vw', transition: 'auto' }}
					className='PhoneIconFix' >
					<FaPhoneAlt />
					{/* <BiMessageDetail /> */}
				</button>
			</a>
			<a href='https://www.facebook.com/WalcoSeed'>
				<button style={{ borderRadius: '5.745744096650192vw', transition: 'auto' }}
					className='FacebookIconFix' >
					<FaFacebook />
					{/* <BiMessageDetail /> */}
				</button>
			</a>
			<a href="https://www.google.com/WalcoSeed">
				<button style={{ borderRadius: '5.745744096650192vw', transition: 'auto' }}
					className='GoogleIconFix' >
					<FaGoogle />
					{/* <BiMessageDetail /> */}
				</button>
			</a>
			<a style={{ bottom: "40px" }} className={showScrollBtn ? 'scrollTop show_btn' : 'scrollTop'}
				onClick={() => {
					window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
				}}

			>
				<TfiAngleDoubleUp />

			</a>
		</>
	)
}

export default memo(Footer)
