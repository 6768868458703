import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
    return (
        <>
            <div className='not-found-d'>
                <div>
                    <h1 className='mb-3'>
                        404
                    </h1>
                    <h3 className='mb-3'>
                        Page not Found
                    </h3>
                    <Link className='btn btn-theme mt-30 secondary btn-md radius animation' to='/'>
                        <button>
                            Go to Home
                        </button>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default NotFound