export const seoData = {
    home: {
        title: "Grain Seed Cleaner Adelaide, Halbury | Walco Seed Cleaning",
        description: "Walco Seed has been at the forefront of seed cleaning technology in Adelaide since 2005. We use advanced grain seed cleaners to give the best result."
    },
    about: {
        title: "Crop Cleaning Expert Balaklava & Halbury | Seed Grader Balaklava",
        description: "Our crop cleaning experts offer a range of seed treatments like seed grading and barley seed treatment with seed graders in Balaklava. Top-notch services."
    },
    whatWeDo: {
        title: "Crop Cleaning Expert Balaklava & Halbury | Seed Grader Balaklava",
        description: "Our crop cleaning experts offer a range of seed treatments like seed grading and barley seed treatment with seed graders in Balaklava. Top-notch services."
    },
    whyGradeSeed: {
        title: "Professional Grain Grading Service Providers In BalaklavaGrain Grading Balaklava | Walco Seed Cleaning",
        description: "Our grain grading services are aimed at identifying, grading and segregating grains with accuracy. Farmers in Balaklava trust us for our expertise.Since its establishment, we offer a broad range of grain grading solutions in Balaklava. We assure guaranteed grading services."
    },
    treatments: {
        title: "Barley Seed Treatment & Crop Treatment services Balaklava Walco Seed Cleaning",
        description: "We provide seed and crop treatment services in Balaklava to treat them for pests and diseases. Even we do barley seed treatment for fungicide resistance.Seed treatments are an important part of a farmer's integrated pest management plan. We assure guaranteed Treatments services."
    },
    grainDetective: {
        title: "The Grain Detective | Walco Seed Cleaning",
        description: "Meta Description: Introducing The Grain Detective by Walco Seed Cleaning. AI-powered tech transforming grain grading for farmers and handlers, enhancing profitability and sustainability in agriculture."
    },
    guarantee: {
        title: "Walco Seed Cleaning | Guarantee",
        description: "Our experts assure guaranteed seed cleaning and processing services to our clients with complete satisfaction. We never compromise with our quality."
    },
    blog: {
        title: "Walco Seed Cleaning | Blog",
        description: "Check out our blog page and read the recent blogs and news on seed quality and harvesting. We regularly update our page with the latest topics."
    },
    contactUs: {
        title: "Walco Seed Cleaning | Contact Us",
        description: "Feel free to contact us anytime with our experts and professionals. We are happy to provide a trusted and prompt seed cleaning service."
    },
    faqs: {
        title: "Walco Seed Cleaning | FAQs",
        description: "Our FAQ page is updated to clarify all your doubts and queries related to our services and support. We believe in a transparent service."
    },
    services: [
        {
            id: 1,
            title: "Seed Dressing Halbury | Walco Seed Cleaning",
            description: "Our advanced seed dressing and grading solutions in Hulbury help to increase the rate of germination and crop yield. Quality seed dressing in Halbury."
        },
        {
            id: 2,
            title: "Seed Grading Balaklava & Mid North | Seed Grader",
            description: "Since 2005, we are offering the best quality seed grading services in Balaklava and Mid North. Experienced seed graders. 100% guaranteed."
        },
        {
            id: 3,
            title: "Seed Cleaning Mid North & Balaklava | Walco Seed Cleaning",
            description: "Walco Seed Cleaning is the leading grain seed cleaner in Mid North and Balaklava that enhances the product quality that is exported. Prompt services."
        }
    ],
    blogDetails: [
        {
            id: 34,
            title: "3 Reasons Why Quality Seeds Count",
            description: "You wouldn't buy a tractor with a tiller that only turned over half the soil and made you go over the same spot again. If it's going to help make your life easier, whatever job you're doing, it has to be fit for purpose."
        },
        {
            id: 33,
            title: "Understanding The Importance Of Seed Vigour",
            description: "Seeds with vigour make plants grow bigger. Not only bigger, but more resistant and ultimately generating a more productive yield."
        },
        {
            id: 32,
            title: "Walco Seed Cleaning Does it Again!!!",
            description: "For some years now, Walco Seed Cleaning has be involved with the Royal Adelaide Show and specifically the Golden Grains Pavilion with ongoing sponsorship for education for our city children."
        },
        {
            id: 31,
            title: "A Brief Note on Seed Treatments for Sustainable Agriculture",
            description: "Seed treatment is a process that helps to protect seeds from damage and enhance their growth."
        },
        {
            id: 30,
            title: "Why Should Organic Farmers Consider Seed Cleaning For Crop Selection?",
            description: "Organic farmers have to take certain factors into account like soil fertility, market demand, and balanced rotations for growing crops, specifically seasonal crops."
        },
        {
            id: 29,
            title: "Major Causes of Crop Diseases and Their Preventive Measures",
            description: "Plants, like all living things, are subject to several diseases."
        },
        {
            id: 28,
            title: "How Beneficial is Nutritional Seed Dressing to the Crops?",
            description: "Agricultural seed dressings are seeing progressive days. The very purpose of getting the seed off to the strongest start is to lay the foundation for healthy germination while protecting the seeds against common stressors."
        },
        {
            id: 27,
            title: "5 Significant Steps to Seed Cleaning",
            description: "Plants, like all living things, are subject to several diseases"
        },
        {
            id: 26,
            title: "How nutritional seed dressing can help seeds cope with stressors?",
            description: "Farmers use the homegrown seed for the next season's yield. A seed that has been subjected to the dressing process shows a better production of the crop."
        },
        {
            id: 25,
            title: "Why Should You Invest In Seed Cleaning?",
            description: "Cleaning seeds has become an urgent necessity for those involved in the agriculture business. Regardless of how you receive seeds for the sowing season,"
        },
        {
            id: 22,
            title: "How Does Seed Cleaning Help In Crop Production",
            description: "Seed cleaning is one of the very important parts of crop production. While most farmers remain unaware of the overarching benefit of seed cleaning."
        },
        {
            id: 21,
            title: "Why are Indent Cylinder Machines Apt As A Grain Cleaner?",
            description: "The indent cylinder device is designed more often than not to separate grain through the kernel period. It separates lengthy kernels from shorter ones."
        },
        {
            id: 20,
            title: "How Seed Grading Improves The Competitive Nature of Seeds?",
            description: "Seed saved for the subsequent 12 months is a purifier, so there is lots less chance of mould, precious silo space isn't being taken up by way of weeds and garbage, and dressed seed reduces the pressure approximately weevils and different bugs."
        },
        {
            id: 19,
            title: "Why Should the Farmer Clean the Grains Before Storage",
            description: "The harvester settings and capacity, the grains' moisture level, and seeds during the harvesting period, the heat damage level and kernel breaking during the harvesting process determine the quality of harvested grains and seeds."
        },
        {
            id: 18,
            title: "How Do Pre-Cleaning and Modern Cleaning Process Clean Grains",
            description: "Cleaning has usually been a vital part of rice milling. It enables you to do away with undesirable substances from the paddy, which includes husks, chaff, soil, and stones. Except,"
        }
    ]
}