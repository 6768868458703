import React, { useState, useEffect, memo } from 'react';
import axios from 'axios'
import { NavLink } from 'react-router-dom';
import HTMLRenderer from 'react-html-renderer';
import Spinner from 'react-bootstrap/Spinner';
import { motion } from 'framer-motion';
import { Helmet } from "react-helmet";
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import { textVariants } from '../common/motion/motion';
import { seoData } from '../../../data';
import { FaPaperPlane } from "react-icons/fa";


const TheGrainDetective = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const [servicelist, setServicelist] = useState([]);
    const [grainDetective, setGrainDetective] = useState([]);
    const [banner, setBanner] = useState('');
    const [pending, setPending] = useState(false)
    const [bannertitle, setBannertitle] = useState([]);
    const [myspinner, setMyspinner] = useState(false);

    const [seoContent, setSeoContent] = useState([]);
    const [seoDes, setSeoDes] = useState('');
    const [seoTitle, setSeoTitle] = useState('');
    const [robot, setRobot] = useState('');
    const [seoScript, setSeoScript] = useState('');
    const [seoKeyword, setSeoKeyword] = useState('');
    const [canonical, setCanonical] = useState('');
    const [featureImage, setFeatureImage] = useState('');

    const [name1, setName1] = useState('');
    const [phone1, setPhone1] = useState('');
    const [email1, setEmail1] = useState('');
    const [message, setMessage] = useState('')
    const [from_australia, setFrom_australia] = useState('')


    const getData = () => {

        axios.get(`${api_url}/getWhygradelist`).then((response) => {
            // console.log(response?.data?.data)
            setServicelist(response?.data?.data);
            setPending(true)
        }).catch((err) => {
            // console.log(err)
            setPending(true)
        });


        axios.get(`${api_url}/getseo/12`).then((response) => {
            setSeoContent(response?.data?.data);
            // console.log(response?.data?.data);
        }).catch((err) => {
            // console.log(err);
        })

        axios.get(`${api_url}/getBanner/11`).then((response) => {
            // console.log(response?.data?.data);
            if (response.status === 200) {
                setBanner(response?.data?.data[0].banner_image)
                setBannertitle(response?.data?.data[0].banner_title)
                setMyspinner(false);
            }
        }).catch((err) => {
            // console.log(err)
        });

        axios.get(`${api_url}/getGrainDetective`).then((response) => {
            // console.log(response?.data?.data)
            setGrainDetective(response?.data?.data);
            setPending(true)
        }).catch((err) => {
            // console.log(err)
            setPending(true)
        });



    };

    useEffect(() => {
        setMyspinner(true);
        window.scrollTo(0, 0)
        getData();
    }, [])


    const handleSEO = () => {
        seoContent?.map((seo) => {

            setRobot(seo.robot);
            setSeoTitle(seo.seo_title);
            setSeoKeyword(seo.seo_keyword)
            setSeoDes(seo.seo_description);
            setCanonical(seo.canonical)
            setSeoScript(seo.script);
            setFeatureImage(seo.feature_image);
        })
    }

    useEffect(() => {
        handleSEO();
    }, [seoContent]);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const onSubmit = () => {
        axios.post(`${api_url}/enquiry`, {
            type: 1,
            name: name1,
            email: email1,
            phone: phone1,
            service_id: '',
            from_australia: from_australia,
            message: message
        }, {
            method: "post",
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                enqSuccess();
                reset({
                    name: '',
                    email: '',
                    phone: '',
                    service: '',
                    message: '',
                })
            }
        }).catch((err) => {
            console.log(err);
        })
    }
    const enqSuccess = () => toast.success('Enquiry send successfully', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });

    return (
        <>

            <Helmet>
                <link rel="canonical" href={canonical?.replace(/\s/g, '-').toLowerCase()} />
                <title>{seoTitle ? seoTitle : seoData.grainDetective.title}</title>
                <meta name="description" content={seoDes ? seoDes : seoData.grainDetective.description} />
                <meta property="og:url" content={canonical?.replace(/\s/g, '-').toLowerCase()} />
                <meta property="og:title" content={seoTitle ? seoTitle : seoData.grainDetective.title} />
                <meta property="og:description" content={seoDes ? seoDes : seoData.grainDetective.description} />
                <meta property="og:image" content={featureImage} />
                <meta name="robots" content={robot} />
                <meta name="twitter:url" content={canonical?.replace(/\s/g, '-').toLowerCase()} />
                <meta name="twitter:title" content={seoTitle ? seoTitle : seoData.grainDetective.title} />
                <meta name="twitter:description" content={seoDes ? seoDes : seoData.grainDetective.description} />
                <meta name="twitter:image" content={featureImage}></meta>
                <script>{seoScript}</script>
            </Helmet>

            <div className={myspinner ? 'spinner_d' : 'd-none'}>
                <Spinner animation="border" className='mySpinner' />
            </div>
            <ToastContainer />
            <div class="breadcrumb-area custom-breadcrumb shadow dark bg-cover text-center text-light "
                style={{ backgroundImage: `url(${api_url}/assets/banner/${banner}` }}>
                <div class="container1">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <h1 class="breadcumb-title">{bannertitle}</h1>
                            <nav class="woocommerce-breadcrumb d-flex justify-content-center"><NavLink
                                end to="/">Home</NavLink>&nbsp;&#47;&nbsp;The Grain Detective</nav>
                        </div>
                    </div>
                </div>
            </div>

            <section class="electrical-panels">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-6">
                            <div class="electrical-panels__left">
                                <div class="electrical-panels__img-box">
                                    <div class="electrical-panels__img-one">
                                        <img src={`${api_url}/assets/cms/${grainDetective[0]?.image}`} alt="walcoseeds" loading='lazy' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6">
                            <motion.div class="electrical-panels__right" variants={textVariants('up', .5)}
                                initial="hidden"
                                whileInView="show"
                                viewport={{ once: true, amount: 1 }}>
                                <div class="section-title text-left">

                                    <h2 class="section-title__title">{grainDetective[0]?.title}</h2>
                                </div>
                                <HTMLRenderer html={grainDetective[0]?.short_description} />
                            </motion.div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="secondsection">
                <motion.div class="container" variants={textVariants('up', 0.1)}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                >
                    <HTMLRenderer html={grainDetective[0]?.description} />

                </motion.div>
            </section>

            <section class="secondsection mb-0">
                <motion.div class="container" variants={textVariants('up', 0.1)}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                >
                    <HTMLRenderer html={grainDetective[0]?.description2} />

                </motion.div>
            </section>

            <section class="electrical-panels">
                <div class="elementor-column container w-100 elementor-col-70 elementor-inner-column elementor-element elementor-element-ace2456"
                    data-id="ace2456" data-element_type="column"  >
                    <div class="elementor-column-wrap elementor-element-populated">
                        <div class="elementor-widget-wrap">
                            <div class="elementor-element elementor-element-6fc793e elementor-widget elementor-widget-agrul_contact_form"
                                data-id="6fc793e" data-element_type="widget"
                                data-widget_type="agrul_contact_form.default">
                                <motion.div class="elementor-widget-container" variants={textVariants('up', 0.1)}
                                    initial="hidden"
                                    whileInView="show"
                                    viewport={{ once: true, amount: 0.1 }}>

                                    <div class="col-tact-stye-one">
                                        <div
                                            class="contact-form-style-one mb-md-50">
                                            <h5 class="sub-title">Have Questions?
                                            </h5>
                                            <h2 class="heading">Send us a Message
                                            </h2>

                                            <div class="wpcf7 no-js"
                                                id="wpcf7-f397-p489-o1" lang="en-US"
                                                dir="ltr">
                                                <div class="screen-reader-response">
                                                    <p role="status"
                                                        aria-live="polite"
                                                        aria-atomic="true"></p>
                                                    <ul></ul>
                                                </div>
                                                <form id="contactForm" onSubmit={handleSubmit(onSubmit)}>


                                                    <div class="row">
                                                        <div class="col-lg-6">
                                                            <div class="form-group">
                                                                <p><span class="wpcf7-form-control-wrap"
                                                                    data-name="text-82">

                                                                    <input className='contact-form-style-one form-control' type="text" placeholder="Name" name="name"

                                                                        {...register('name', {
                                                                            required: true,
                                                                            onChange: (e) => setName1(e.target.value)

                                                                        })}
                                                                    />
                                                                    {errors.name && errors.name.type && <div className="help-block with-errors text-danger">please enter your name</div>}



                                                                </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="form-group">
                                                                <p><span class="wpcf7-form-control-wrap"
                                                                    data-name="email-162"> <input className='contact-form-style-one form-control' type="email" placeholder="Company Email" name="email"


                                                                        value={email1}

                                                                        {...register('email', {
                                                                            required: true,
                                                                            onChange: (e) => setEmail1(e.target.value)

                                                                        })}
                                                                    />
                                                                    {errors.email && errors.email.type && <div className="help-block with-errors text-danger">please enter Company Email</div>}
                                                                </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-lg-6">
                                                            <div class="form-group">
                                                                <p><span class="wpcf7-form-control-wrap"
                                                                    data-name="tel-519"><input className='contact-form-style-one form-control' type="text" placeholder="Phone" name="Phone"


                                                                        value={phone1}

                                                                        {...register('phone', {
                                                                            required: true,
                                                                            onChange: (e) => setPhone1(e.target.value)

                                                                        })}
                                                                    />
                                                                    {errors.phone && errors.phone.type && <div className="help-block with-errors text-danger">please enter your number</div>}
                                                                </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="form-group">
                                                                <p><span class="wpcf7-form-control-wrap"
                                                                    data-name="tel-519"><select className='contact-form-style-one form-control' placeholder="Phone" name="from_australia"


                                                                        value={from_australia}

                                                                        {...register('from_australia', {
                                                                            required: true,
                                                                            onChange: (e) => setFrom_australia(e.target.value)

                                                                        })}
                                                                    >
                                                                        <option value="">Are you based in Australia?</option>
                                                                        <option value="0">Yes</option>
                                                                        <option value="1">No</option>
                                                                    </select>
                                                                    {errors.from_australia && errors.from_australia.type && <div className="help-block with-errors text-danger">please select type</div>}
                                                                </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div
                                                                class="form-group comments">
                                                                <p><span class="wpcf7-form-control-wrap"
                                                                    data-name="textarea-648"> <textarea cols={60} rows={6} className='contact-form-style-one wpcf7-form-control wpcf7-textarea' name="message" placeholder="Where do you see the Grain Detective Benefiting you?"
                                                                        value={message}

                                                                        {...register('message', {
                                                                            required: true,
                                                                            onChange: (e) => setMessage(e.target.value)

                                                                        })}
                                                                    />
                                                                    {errors.message && errors.message.type && <div className="help-block with-errors text-danger">please enter your message</div>}

                                                                </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <p><button type="submit"
                                                                name="submit"
                                                                id="submit"><i
                                                                    class="fa fa-paper-plane"> <FaPaperPlane /> </i>
                                                                Get in
                                                                Touch</button>
                                                            </p>
                                                        </div>
                                                    </div>

                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                </motion.div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default TheGrainDetective