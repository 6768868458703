import Logo from '../assets/image/logo1.png';

import React, { useLayoutEffect, useState, useEffect, useRef, memo } from 'react';
import axios from 'axios';

import { FiPhone } from 'react-icons/fi';
import { FaEnvelopeOpenText, FaTimes, FaBars, FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa'
import Banner1 from '../assets/image/brush-down.png';
import { NavLink, Link } from 'react-router-dom';
import { BiTimeFive } from 'react-icons/bi'

const Header = () => {

	const api_url = process.env.REACT_APP_API_URL;


	const [data, setData] = useState([]);
	const [logo, setLogo] = useState('');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');


	const [therapiesMenu, setTherapiesMenu] = useState([]);

	const [click, setClick] = React.useState(false);
	const handleClick = () => setClick(!click);
	const Close = () => setClick(false);

	// sticky header
	const headerStickyRef = useRef();
	let [check, setCheck] = useState(true);
	const sticky = useStickyHeader(50);
	const headerClasses = `main-header ${(sticky && check) ? 'sticky' : 'main-header'}`
	const ref = useRef();

	//    main-menu


	const { clientHeight } = ref;

	const checkChange = (value) => {
		setCheck(value);
	};

	const getData = () => {
		axios.get(`${api_url}/getFooter`).then((response) => {

			setLogo(response?.data?.data[0].logo)
			console.log(response?.data?.data[0].logo);
			setPhone(response?.data?.data[0].phone)
			setEmail(response?.data?.data[0].enq_email)

			setData(response?.data?.data)

		}).catch((err) => {
			// console.log(err);
		});


		axios.get(`${api_url}/getmenuService`).then((response) => {
			// console.log(response.data);
			// setLogo(response?.data?.data[0].logo)
			setTherapiesMenu(response?.data?.data)
		}).catch((err) => {
			// console.log(err);
		})



	}
	useEffect(() => {
		getData();
	}, []);





	function useStickyHeader(offset = 0) {
		const [stick, setStick] = useState(false);

		const handleScroll = () => {
			setStick(window.scrollY > offset);
		};

		useLayoutEffect(() => {
			window.addEventListener('scroll', handleScroll);

			return (() => {
				window.removeEventListener('scroll', handleScroll);
			});
		});

		return stick;
	}
	const [clicked, setClicked] = useState(false);
	const [handleDrop, setHandleDrop] = useState(false);

	const handleClicked = () => {
		setClicked(!clicked);
		setHandleDrop(false)
	}

	useEffect(() => {
		const timer = setTimeout(() => {
			setHandleDrop(true);
		}, 1000);
		return () => clearTimeout(timer);
	}, [handleDrop]);

	const [submenu, setSubmenu] = useState(false);
	const [SubhandleDrop, setSubHandleDrop] = useState(false);

	const SubhandleClicked = () => {
		setSubmenu(!submenu);
		setSubHandleDrop(false)
	}


	return (
		<>

			<div data-elementor-type="wp-post" data-elementor-id="1191" class="elementor elementor-1191">
				<div class="elementor-inner">
					<div class="elementor-section-wrap">
						<section
							class="elementor-section elementor-top-section elementor-element elementor-element-d8a4ef3 elementor-section-full_width elementor-section-stretched bg-dark1 elementor-section-height-default elementor-section-height-default"
							data-id="d8a4ef3" data-element_type="section" data-settings="">
							<div class="elementor-container elementor-column-gap-no">
								<div class="elementor-row">
									<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-068c09e"
										data-id="068c09e" data-element_type="column">
										<div class="elementor-column-wrap elementor-element-populated">
											<div class="elementor-widget-wrap">
												<div class="elementor-element elementor-element-fe8bac4 elementor-widget elementor-widget-agrul_header_topbar"
													data-id="fe8bac4" data-element_type="widget"
													data-widget_type="agrul_header_topbar.default">
													<div class="elementor-widget-container" ref={headerStickyRef}>
														<div class="top-bar top-style-one1 text-light ">
															<div class="container mt-1">
																<div class="d-flex justify-content-center">

																	<div className='attr-right'>
																		<div class="attr-nav">
																			<ul>
																				<li class="opening-hours">
																					<div class="call">
																						<div class="icon">
																						</div>
																						<div class="info d-flex justify-content-center align-items-baseline gap-2">
																							<div class="">
																								<BiTimeFive class=" " />
																							</div>

																							<div className=''>
																								<p class="text-white "> Nov to May we are open longer hours to service farmers Mon - Sat 7:00am - 7:00pm During the off season we are open Monday- Friday 9:00am - 4:00pm Sunday - CLOSED</p>

																							</div>
																						</div>
																					</div>
																				</li>
																			</ul>
																		</div>

																	</div>
																</div>
																{/* <div class="row align-center">
																	<div class="col-xl-4 col-lg-4 col-md-4 info">
																		<ul>
																			<li>
																				<div class="icon">
																					<i class="fas fa-phone-alt"><FiPhone /></i>
																				</div>
																				<div class="content">
																					<span>Call Anytime</span>
																					<a href={`tel:${phone}`}>{phone}</a>
																				</div>
																			</li>
																		</ul>
																	</div>
																	<div class="col-xl-4 col-lg-4 col-md-4 logo">
																		<a href="/#"><img
																			src={`assets/logo/${logo}`} alt="walcoseed" /></a>
																	</div>
																	<div class="col-xl-4 col-lg-4 col-md-4 text-end info">
																		<ul>
																			<li>
																				<div class="icon">
																					<i class="fas fa-comments-alt-dollar"><FaEnvelopeOpenText /></i>
																				</div>
																				<div class="content">
																					<span>Write Email</span>
																					<a href={`mailto:${email}`}>{email}</a>
																				</div>
																			</li>
																		</ul>
																	</div>
																</div> */}
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>

						<section
							class="elementor-section elementor-top-section elementor-element elementor-element-d8a4ef3 elementor-section-full_width elementor-section-stretched bg-dark1 elementor-section-height-default elementor-section-height-default"
							data-id="d8a4ef3" data-element_type="section" data-settings="">
							<div class="elementor-container elementor-column-gap-no">
								<div class="elementor-row">
									<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-068c09e"
										data-id="068c09e" data-element_type="column">
										<div class="elementor-column-wrap elementor-element-populated">
											<div class="elementor-widget-wrap">
												<div class="elementor-element elementor-element-fe8bac4 elementor-widget elementor-widget-agrul_header_topbar"
													data-id="fe8bac4" data-element_type="widget"
													data-widget_type="agrul_header_topbar.default">
													<div class="elementor-widget-container" ref={headerStickyRef}>
														<div class="top-bar top-style-one text-light">
															<div class="container">
																<div class="row align-center">
																	<div class="col-xl-4 col-lg-4 col-md-4 info">
																		<ul>
																			<li>
																				<div class="icon">
																					<a href='https://www.facebook.com/WalcoSeed' target='_blank'>
																						<i class="fas fa-phone-alt Iconsizefix"><FaFacebookF /></i>
																					</a>
																					<a href='https://au.linkedin.com/in/kurt-walter-49721b71' target='_blank'>
																						<i class="fas fa-phone-alt Iconsizefix"><FaLinkedinIn /></i>
																					</a>
																					<a href='https://twitter.com/walcoseedau?lang=en' target='_blank'>
																						<i class="fas fa-phone-alt Iconsizefix"><FaTwitter /></i>
																					</a>
																				</div>
																				{/* <div class="content">
																					<span>Call Anytime</span>
																					<a href={`tel:${phone}`}>{phone}</a>
																				</div> */}
																			</li>
																		</ul>
																	</div>
																	<div class="col-xl-4 col-lg-4 col-md-4 logo">
																		<Link href="/"><img
																			src={`${api_url}/assets/logo/${logo}`} alt="walcoseed" loading='lazy' /></Link>
																	</div>
																	<div class="col-xl-4 col-lg-4 col-md-4 text-end info">
																		<ul>
																			<li>
																				<div class="icon">
																					<i class="fas fa-comments-alt-dollar Iconsizefix"><FaEnvelopeOpenText /></i>
																				</div>
																				<div class="content">
																					<span>Write Email</span>
																					<a href={`mailto:${email}`}>{email}</a>
																				</div>
																			</li>
																		</ul>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>

						<section
							class="elementor-section elementor-top-section elementor-element elementor-element-9a745bc elementor-section-full_width elementor-section-stretched elementor-section-height-default elementor-section-height-default"
							data-id="9a745bc" data-element_type="section" data-settings="">
							<div class="elementor-container elementor-column-gap-no">
								<div class="elementor-row">
									<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c46b95b"
										data-id="c46b95b" data-element_type="column">
										<div class="elementor-column-wrap elementor-element-populated">
											<div class="elementor-widget-wrap">
												<div class="elementor-element elementor-element-03568ab elementor-widget elementor-widget-agrul_header"
													data-id="03568ab" data-element_type="widget"
													data-widget_type="agrul_header.default">
													<div class="elementor-widget-container">

														<header className={headerClasses} ref={headerStickyRef}>
															<nav
																class="navbar mobile-sidenav less-logo navbar-common navbar-default validnavs navbar-sticky">

																<div class="top-search">
																	<div class="container-xl">
																		<div class="input-group">
																			<span class="input-group-addon"><i
																				class="fa fa-search"></i></span>
																			<input type="text" class="form-control"
																				placeholder="Search" />
																			<span class="input-group-addon close-search"><i
																				class="fa fa-times"></i></span>
																		</div>
																	</div>
																</div>

																<div
																	class="custom_header container-fluid d-flex align-items-center">
																	<div class="navbar-header ">

																		<button type="button" class="navbar-toggle" onClick={handleClicked}
																			data-toggle="collapse" data-target="#navbar-menu">
																			<i class="fa fa-bars"><FaBars /></i>
																		</button>

																	</div>

																	<div class="navbar-header mob_img_logo ">
																		<Link lass="navbar-brand" href="/"><img
																			alt="walcoseed" src={`${api_url}/assets/logo/${logo}`} loading='lazy' width={117} height={80} /></Link>
																	</div>


																	<div className={clicked ? 'collapse navbar-collapse collapse-mobile show' : 'collapse navbar-collapse '} id="navbar-menu">

																		<NavLink end to='/' onClick={() => setClicked(false)}><img src={`${api_url}/assets/logo/${logo}`} width={117} height={80} alt="walcoseed" loading='lazy' /></NavLink>

																		<button type="button" class="navbar-toggle" onClick={handleClicked}
																			data-toggle="collapse" data-target="#navbar-menu">
																			<i class="fa fa-times"><FaTimes /></i>
																		</button>

																		<ul data-in="fadeInDown" data-out="fadeOutUp"
																			class="nav navbar-nav navbar-left"
																			id="menu-primary-menu">


																			<li id="menu-item-979"
																				className={window.innerWidth < 992 ? 'd-none' : null}>
																				<Link title="Logo" end to="/" class={sticky ? 'd-inline-block p-0' : 'd-none'}
																				><img src={`${api_url}/assets/logo/${logo}`} alt="walcoseed" class="" width={150} loading='lazy' height={100} /></Link>

																			</li>


																			<li id="menu-item-979"
																				class="menu-item nav_text_active_hover menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-979 dropdown">
																				<NavLink title="Home" end to="/" class="dropdown-toggle d-block" onClick={() => setClicked(false)}
																				>Home</NavLink>

																			</li>
																			<li id="menu-item-1275"
																				class="menu-item  nav_text_active_hover menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-1275 dropdown">
																				<NavLink to="/about" end title="About Us"
																					class="dropdown-toggle" onClick={() => setClicked(false)}
																					data-toggle="dropdown">About Us</NavLink>

																			</li>

																			<li id="menu-item-1303"
																				class="menu-item nav_text_active_hover dropdown-toggle menu-item-type-custom  menu-item-object-custom menu-item-has-children menu-item-1303 dropdown">

																				<NavLink end title="What We Do" to="/what-we-do" onClick={() => setClicked(false)}
																					class="dropdown-toggle"
																					data-toggle="dropdown">What We Do</NavLink>
																				<ul class=" dropdown-menu ">
																					{therapiesMenu?.map((data) => {
																						return (
																							<li id="menu-item-1305"
																								class="menu-item main_text_hover_dropdown menu-item-type-post_type menu-item-object-page menu-item-1305">
																								<NavLink end to={`/services/${data.name.replace(/\s/g, '-').toLowerCase()}/${data.id}`} activeclassname="active" className="nav-NavLink" onClick={() => setClicked(false)}>{data.name}</NavLink>

																							</li>

																						)
																					})}

																				</ul>
																			</li>
																			<li id="menu-item-1287"
																				class="menu-item nav_text_active_hover menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-1287 dropdown">
																				<NavLink end title="Why Grade Seed" to="/why-grade-seed" onClick={() => setClicked(false)}
																					class="dropdown-toggle"
																					data-toggle="dropdown">Why Grade Seed </NavLink>

																			</li>
																			<li id="menu-item-1287"
																				class="menu-item nav_text_active_hover menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-1287 dropdown">
																				<NavLink end title="The Grain Detective" to="/the-grain-detective" onClick={() => setClicked(false)}
																					class="dropdown-toggle"
																					data-toggle="dropdown">The Grain Detective </NavLink>

																			</li>
																			<li id="menu-item-1287"
																				class="menu-item nav_text_active_hover menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-1287 dropdown">
																				<NavLink end title="Treatment" to="/treatments" onClick={() => setClicked(false)}
																					class="dropdown-toggle"
																					data-toggle="dropdown">Treatments</NavLink>

																			</li>
																			<li id="menu-item-1287"
																				class="menu-item nav_text_active_hover menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-1287 dropdown">
																				<NavLink end title="Guarantee" to="/guarantee" onClick={() => setClicked(false)}
																					class="dropdown-toggle"
																					data-toggle="dropdown">Guarantee</NavLink>

																			</li>
																			<li id="menu-item-2054"
																				class="menu-item nav_text_active_hover menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-2054 dropdown">
																				<NavLink end title="Blog" to="/blog" class="dropdown-toggle" onClick={() => setClicked(false)}
																					data-toggle="dropdown">Blog</NavLink>

																			</li>
																			<li id="menu-item-1638"
																				class="menu-item nav_text_active_hover menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-1638 dropdown">
																				<NavLink end title="Contact Us" to="/contact-us" class="dropdown-toggle" onClick={() => setClicked(false)}
																					data-toggle="dropdown">Contact US</NavLink>

																			</li>
																			<li id="menu-item-2054"
																				class="menu-item nav_text_active_hover menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-2054 dropdown">
																				<NavLink end title="FAQs" to="/faqs" class="dropdown-toggle" onClick={() => setClicked(false)}
																					data-toggle="dropdown">FAQs</NavLink>

																			</li>

																		</ul>
																		<div className='attr-right mob_attr_right'>
																			<div class="attr-nav">
																				<ul>
																					<li class="opening-hours mob_opening-hours">
																						<div class="call">
																							<div class="icon">
																							</div>
																							<div class="info d-flex justify-content-center align-items-baseline gap-2">
																								<div class="">
																									<BiTimeFive class=" " />
																								</div>

																								<div className=''>
																									<p class="text-white "> Nov to May we are open longer hours to service farmers Mon - Sat 7:00am - 7:00pm During the off season we are open Monday- Friday 9:00am - 4:00pm Sunday - CLOSED</p>

																								</div>
																							</div>
																						</div>
																					</li>
																				</ul>
																			</div>

																		</div>
																	</div>
																	<div className='top-style-one border-0' style={sticky ? { } : { maxWidth: "300px" }}>
																		<div class="info">
																			<ul>
																				<li>
																					<div class="icon">
																						<i class="fas fa-phone-alt Iconsizefix"><FiPhone /></i>

																					</div>
																					<div class="content">
																						<span>Call Anytime</span>
																						<a href={`tel:${phone}`}>{phone}</a>
																					</div>
																				</li>
																			</ul>
																		</div>
																	</div>
																</div>

																{/* <div class="overlay-screen" style={{ backgroundImage: `url(${Banner1})`, backgroundRepeat: 'no-repeat' }}></div> */}

															</nav>
														</header>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>


		</>
	)
}

export default memo(Header)
