import 'bootstrap/dist/css/bootstrap.min.css';
import * as React from 'react';
import { Route, Router, Routes, BrowserRouter } from 'react-router-dom';
import { lazy } from 'react';
import Frontend from './components/web/Frontend';
import 'react-toastify/dist/ReactToastify.css';
import HandleRouting from './components/web/common/HandleRouting';
import NotFound from './components/web/common/NotFound';
import TheGrainDetective from './components/web/pages/TheGrainDetective';
const Home = lazy(() => import('./components/web/pages/Home'));
const About = lazy(() => import('./components/web/pages/About'));
const Treatment = lazy(() => import('./components/web/pages/Treatment'));
const Service = lazy(() => import('./components/web/pages/Service'));
const Servicedetails = lazy(() => import('./components/web/pages/Servicedetails'));
const Whygrade = lazy(() => import('./components/web/pages/Whygrade'));
const Blog = lazy(() => import('./components/web/pages/Blog'));
const Blogdetails = lazy(() => import('./components/web/pages/Blogdetails'));
const Contact = lazy(() => import('./components/web/pages/Contact'));
const Privacy = lazy(() => import('./components/web/pages/Privacy'));
const Disclaimer = lazy(() => import('./components/web/pages/Disclaimer'));
const Guarantee = lazy(() => import('./components/web/pages/Guarantee'));
const Faqs = lazy(() => import('./components/web/pages/FAQ'));


function App() {
  let auth = localStorage.getItem("auth");
  console.log(auth);
  return (
    <>
      <React.Fragment>
        <BrowserRouter>

          <Routes>
            <Route exact path='/' element={<Frontend />}>
              <Route path='/' element={<Home />} />
              <Route exact path='/about' element={<About />} />
              <Route exact path='/treatments' element={<Treatment />} />
              <Route exact path='/what-we-do' element={<Service />} />
              <Route exact path='/why-grade-seed' element={<Whygrade />} />
              <Route exact path='/the-grain-detective' element={<TheGrainDetective />} />
              <Route exact path='/contact-us' element={<Contact />} />
              <Route exact path='/blog' element={<Blog />} />
              <Route exact path='/blog/:name/:id' element={<Blogdetails />} />
              <Route exact path='/services/:name/:id' element={<Servicedetails />} />
              <Route exact path='/privacy-statement' element={<Privacy />} />
              <Route exact path='/disclaimer' element={<Disclaimer />} />
              <Route exact path='/guarantee' element={<Guarantee />} />
              <Route exact path='/faqs' element={<Faqs />} />

              <Route exact path='/services/seed-dressing' element={<HandleRouting />} />
              <Route exact path='/services/seed-grading' element={<HandleRouting />} />
              <Route exact path='/services/seed-cleaning' element={<HandleRouting />} />

              <Route exact path='/blog/3-reasons-why-quality-seeds-count' element={<HandleRouting />} />
              <Route exact path='/blog/understanding-the-importance-of-seed-vigour' element={<HandleRouting />} />
              <Route exact path='/blog/walco-seed-cleaning-does-it-again' element={<HandleRouting />} />
              <Route exact path='/blog/a-brief-note-on-seed-treatments-for-sustainable-agriculture' element={<HandleRouting />} />
              <Route exact path='/blog/why-should-organic-farmers-consider-seed-cleaning-for-crop-selection' element={<HandleRouting />} />
              <Route exact path='/blog/major-causes-of-crop-diseases-and-their-preventive-measures' element={<HandleRouting />} />
              <Route exact path='/blog/how-beneficial-is-nutritional-seed-dressing-to-the-crops' element={<HandleRouting />} />
              <Route exact path='/blog/5-significant-steps-to-seed-cleaning' element={<HandleRouting />} />
              <Route exact path='/blog/how-nutritional-seed-dressing-can-help-seeds-cope-with-stressors' element={<HandleRouting />} />
              <Route exact path='/blog/why-should-you-invest-in-seed-cleaning' element={<HandleRouting />} />

              <Route path='/*' element={<NotFound />} />

            </Route>
          </Routes>

        </BrowserRouter>
      </React.Fragment >
    </>
  );
}

export default App;
